// IMPORTANT: !!!!DO NOT CHANGE THE FILE EXTENSION!!!!
// This file has a .tsx extension so Storybook can read/interpret its JSDocs
// SEE: https://github.com/storybookjs/storybook/discussions/15512#discussioncomment-1032862

import { createContext, ReactNode, useContext } from 'react'

import { ConsoleConfig, consoleConfigs, ConsoleID } from 'lib/consoleConfigs'

export const ConsoleConfigContext = createContext<ConsoleConfig | undefined>(undefined)

/**
 * Access the current console’s configuration, or a specific config by ID.
 *
 * NOTE: looking up the “current” console can only be done within a
 * `ConsoleConfigProvider`.
 *
 * ```tsx
 * // Print the current console name
 * const currentConfig = useConsoleConfig()
 * return <h1>Currently viewing {currentConfig.name}</h1>
 * ```
 * ```tsx
 * // Link to another console
 * const personServiceConfig = useConsoleConfig(ConsoleID.PersonService)
 * return <a href={personServiceConfig.path}>{personServiceConfig.name}</a>
 * ```
 */
export const useConsoleConfig = (id?: ConsoleID) => {
  const currentConfig = useContext(ConsoleConfigContext)

  if (typeof id !== 'undefined') {
    const otherConfig = consoleConfigs[id]
    if (!otherConfig) throw new Error(`No console config found matching id: ${id}`)

    return otherConfig
  }

  if (currentConfig) return currentConfig

  throw new Error(
    'useConsoleConfig must be provided a console ID or used within a ConsoleConfigProvider',
  )
}

export interface ConsoleConfigProviderProps {
  config: ConsoleConfig
  children: ReactNode
}

/**
 * Provides a console config to child components
 *
 * ```tsx
 * <ConsoleConfigProvider config={consoleConfig}>
 *   You can useConsoleConfig() within here…
 * </ConsoleConfigProvider>
 * ```
 */
export const ConsoleConfigProvider = ({ config, children }: ConsoleConfigProviderProps) => {
  return <ConsoleConfigContext.Provider value={config}>{children}</ConsoleConfigContext.Provider>
}
