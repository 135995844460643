import { action, Action } from 'easy-peasy'

import { JWT, JWTAudience } from './authTokensModel.types'

export interface AuthTokensModel {
  tokens: Record<JWTAudience, JWT>

  add: Action<AuthTokensModel, { audience: JWTAudience; token: JWT }>
}

export const authTokensModel: AuthTokensModel = {
  tokens: {},

  add: action((state, { audience, token }) => {
    state.tokens[audience] = token
  }),
}
