import { lazy } from 'react'

import AccountIcon from '@mui/icons-material/AccountCircle'

import { ConsoleConfig, ConsoleID } from 'lib/consoleConfigs'

import {
  PERSON_SERVICE_API_ENDPOINT,
  PERSON_SERVICE_AUTH0_AUDIENCE,
} from '../utils/environment-variables'

export const personServiceConfig = {
  id: ConsoleID.PersonService,

  name: 'Person Service',
  Icon: AccountIcon,
  description: 'The single source of truth for “People” data',

  path: '/person-service',
  Console: lazy(() => import('consoles/person-service')),

  apis: {
    personService: {
      uri: PERSON_SERVICE_API_ENDPOINT,
      audience: PERSON_SERVICE_AUTH0_AUDIENCE,
      permissions: {
        namespace: 'person_service',
      },
      resourceAliases: {
        deep_search: 'legacy_person_contact_methods',
      },
    },
  },

  permissions: {
    rolePrefix: 'person_service',
    hasResourceCRUDPermissions: true,
  },

  team: {
    name: 'Platform Services',
    href: 'https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/2799108932/Platform+Services+Team',
  },
} satisfies ConsoleConfig
