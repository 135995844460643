import { lazy } from 'react'

import CategoryIcon from '@mui/icons-material/Category'

import { ConsoleConfig, ConsoleID } from 'lib/consoleConfigs'

import {
  PRODUCT_CATALOG_API_ENDPOINT,
  PRODUCT_CATALOG_AUTH0_AUDIENCE,
} from '../utils/environment-variables'

export const productCatalogConfig = {
  id: ConsoleID.ProductCatalog,

  name: 'Product Catalog',
  Icon: CategoryIcon,
  description: 'All the Products and Carriers that Assurance offers',

  path: '/product-catalog',
  Console: lazy(() => import('consoles/product-catalog')),

  apis: {
    productCatalog: {
      uri: PRODUCT_CATALOG_API_ENDPOINT,
      audience: PRODUCT_CATALOG_AUTH0_AUDIENCE,
      permissions: {
        namespace: 'product_catalog',
      },
    },
  },

  permissions: {
    rolePrefix: 'product_catalog',
    hasResourceCRUDPermissions: false,
  },

  team: {
    name: 'CPS',
    href: 'https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/3217359205/Carrier+Platform+Services+CPS+Team',
  },
} satisfies ConsoleConfig
