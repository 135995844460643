// IMPORTANT: !!!!DO NOT CHANGE THE FILE EXTENSION!!!!
// This file has a .tsx extension so Storybook can read/interpret its JSDocs
// SEE: https://github.com/storybookjs/storybook/discussions/15512#discussioncomment-1032862

import { useRef } from 'react'

/**
 * Returns a promise function that will resolve when the given state changes.
 * Useful for the rare cases when `useEffect()` isn’t an option.
 *
 * Inspired by [this Stack Overflow](https://stackoverflow.com/a/74180433/625710).
 *
 * ```ts
 * const aboveTwo = useStateChangePromise(count, count => count > 2)
 * …
 * await aboveTwo()
 *
 * ```
 */
export const useStateChangePromise = <S, ResolvedS extends S = NonNullable<S>>(
  state: S,
  isResolved: (state: S) => boolean,
) => {
  const resolver = useRef<(val: S) => void>()
  const promise = useRef<Promise<S>>()

  if (!resolver.current) {
    promise.current = new Promise((resolve) => {
      resolver.current = resolve
    })
  }

  if (isResolved(state) && resolver.current) {
    resolver.current(state)
    resolver.current = undefined
  }

  return () => promise.current as Promise<ResolvedS>
}
