import { lazy } from 'react'

import PaymentsIcon from '@mui/icons-material/Payments'

import { ConsoleConfig, ConsoleID } from 'lib/consoleConfigs'

import {
  COMMISSIONS_OPERATIONS_PORTAL_API_ENDPOINT,
  COMMISSIONS_OPERATIONS_PORTAL_AUTH0_AUDIENCE,
} from '../utils/environment-variables'

export const commissionsOperationsPortalConfig = {
  id: ConsoleID.CommissionsOperationsPortal,

  name: 'Commissions Operations Portal',
  Icon: PaymentsIcon,
  description: 'View information and perform operational actions related to agent commissions',

  path: '/operations-portal',
  Console: lazy(() => import('consoles/commissions-operations-portal')),

  apis: {
    commissionService: {
      uri: COMMISSIONS_OPERATIONS_PORTAL_API_ENDPOINT,
      audience: COMMISSIONS_OPERATIONS_PORTAL_AUTH0_AUDIENCE,
      permissions: {
        namespace: 'commissions_operations_portal',
      },
    },
  },

  permissions: {
    rolePrefix: 'commissions',
    hasResourceCRUDPermissions: false,
  },

  team: {
    name: 'Commissions',
    href: 'https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/1976927399/Team+COMMISSIONS+Commission+Agent+Dashboard+Agent+Profile',
  },
} satisfies ConsoleConfig
