import { AdminContext, defaultI18nProvider } from 'react-admin'

import { useTheme } from '@mui/material'

import { ConsoleHeader } from 'components/ConsoleLayout/ConsoleHeader'
import { ConsoleConfigProvider, useAuth0AuthProvider } from 'hooks'
import { ConsoleConfig } from 'lib/consoleConfigs'

import styles from './AppHeader.module.scss'

// HACK: useAuth0AuthProvider requires a ConsoleConfig context to check permissions
// against. We don’t have a ConsoleConfig context in the AppHeader, so we create a
// partial one here.
//
// @ts-expect-error - we only provide a partial config
const MOCK_APP_CONSOLE_CONFIG: ConsoleConfig = {
  permissions: {
    hasResourceCRUDPermissions: false,
  },
}

// HACK: The AppHeader is used to render a standard React Admin header *outside*
// of a React Admin app. We provide it with enough context to get it to render.
export const AppHeader = () => {
  return (
    <ConsoleConfigProvider config={MOCK_APP_CONSOLE_CONFIG}>
      <HeaderWithConsoleContext />
    </ConsoleConfigProvider>
  )
}

const HeaderWithConsoleContext = () => {
  const authProvider = useAuth0AuthProvider()
  const theme = useTheme()

  return (
    <AdminContext authProvider={authProvider} i18nProvider={defaultI18nProvider} theme={theme}>
      <ConsoleHeader className={styles.header} />
    </AdminContext>
  )
}
