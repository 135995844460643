// IMPORTANT: !!!!DO NOT CHANGE THE FILE EXTENSION!!!!
// This file has a .tsx extension so Storybook can read/interpret its JSDocs
// SEE: https://github.com/storybookjs/storybook/discussions/15512#discussioncomment-1032862

import { useMemo } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { useConsoleConfig } from 'hooks/useConsoleConfig'
import { ConsoleID } from 'lib/consoleConfigs'

export const ASSURANCE_ROLES = 'https://assurance.com/roles'

/**
 * Returns whether the current user can access a specified console.
 *
 * ```ts
 * // Can the current user access the "Foo" console?
 * const canAccessConsole = useCanAccessConsole(ConsoleID.Foo) // => true/false
 * ```
 */
export const useCanAccessConsole = (consoleID?: ConsoleID) => {
  const {
    permissions: { rolePrefix },
  } = useConsoleConfig(consoleID)

  const { user } = useAuth0()

  const roles = user?.[ASSURANCE_ROLES]

  const canAccessConsole = useMemo(() => {
    if (!rolePrefix) return true
    if (!roles || !(roles instanceof Array)) {
      console.error('Unable to determine current user roles')
      return true
    }

    return !!roles.find(
      (role) => typeof role === 'string' && role.match(new RegExp(`^${rolePrefix}\\.`)),
    )
  }, [rolePrefix, roles])

  return canAccessConsole
}
