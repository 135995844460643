import { affixConfig } from 'consoles/affix/config/affixConfig'
import { commissionsOperationsPortalConfig } from 'consoles/commissions-operations-portal/config/commissionsOperationsPortalConfig'
import { productEligibilityServiceConfig } from 'consoles/eligibility-service/config/productEligibilityServiceConfig'
import { identityServiceConfig } from 'consoles/identity-service/config/identityServiceConfig'
import { personServiceConfig } from 'consoles/person-service/config/personServiceConfig'
import { pftmConfig } from 'consoles/pftm/config/pftmConfig'
import { productCatalogConfig } from 'consoles/product-catalog/config/productCatalogConfig'

import { ConsoleConfig, ConsoleID } from './consoleConfigs.types'

export const consoleConfigs: Record<ConsoleID, ConsoleConfig> = {
  [ConsoleID.Affix]: affixConfig,
  [ConsoleID.CommissionsOperationsPortal]: commissionsOperationsPortalConfig,
  [ConsoleID.IdentityService]: identityServiceConfig,
  [ConsoleID.PersonService]: personServiceConfig,
  [ConsoleID.PFTM]: pftmConfig,
  [ConsoleID.ProductCatalog]: productCatalogConfig,
  [ConsoleID.ProductEligibilityService]: productEligibilityServiceConfig,
}
