import { ReactNode } from 'react'

import ReplayIcon from '@mui/icons-material/Replay'
import { Alert, AlertTitle, Button, Grid } from '@mui/material'

import {
  OAuthError,
  useAuth0,
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react'

import { AppLayout } from 'components/App/AppLayout'

interface RequireAuthenticationProps extends WithAuthenticationRequiredOptions {
  children: ReactNode
}

const AUTO_RECOVERABLE_ERRORS = [
  'mfa_required', // we sometimes get this, not sure why it wouldn’t auto redirect
  'Invalid state',
]

export const RequireAuthentication = ({ children, ...options }: RequireAuthenticationProps) => {
  const { error, loginWithRedirect, logout } = useAuth0()
  const Authenticated = withAuthenticationRequired(() => <>{children}</>, options)

  if (!error) return <Authenticated />

  const errorName = error instanceof OAuthError ? error.error : error.message

  const onRetryClick = () => {
    logout({
      returnTo: window.location.origin,
    })
  }

  if (AUTO_RECOVERABLE_ERRORS.includes(errorName)) {
    loginWithRedirect(options)
    return null
  }

  // TODO: Create [Error Boundary](https://reactjs.org/docs/error-boundaries.html) to handle all app errors
  return (
    <AppLayout centered contained>
      <Grid item xs={12} md={8} lg={6} sx={{ mb: '30vh' }}>
        <Alert
          severity='error'
          variant='outlined'
          action={
            <Button
              aria-label='Retry login'
              startIcon={<ReplayIcon />}
              color='error'
              onClick={onRetryClick}
            >
              Retry login
            </Button>
          }
        >
          <AlertTitle>
            Error: <code>{errorName}</code>
          </AlertTitle>
          {error.message}
        </Alert>
      </Grid>
    </AppLayout>
  )
}
