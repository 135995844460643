// IMPORTANT: !!!!DO NOT CHANGE THE FILE EXTENSION!!!!
// This file has a .tsx extension so Storybook can read/interpret its JSDocs
// SEE: https://github.com/storybookjs/storybook/discussions/15512#discussioncomment-1032862

import { useMemo, useState } from 'react'

import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { useAuthHelpers } from 'hooks/useAuthHelpers'
import { APIConfig } from 'lib/consoleConfigs'

export interface AuthenticatedApolloClientHookOptions {
  api: APIConfig

  /**
   * Apollo Client options
   *
   * **NOTE:** These are only instantiated once, on first render. Any changes
   * will require a browser refresh to propagate (i.e. hot-reloading won’t work)
   *
   * - See [Apollo Docs - Client options](https://www.apollographql.com/docs/react/api/core/ApolloClient#options)
   */
  clientOptions?: Partial<ApolloClientOptions<NormalizedCacheObject>>

  /**
   * Override the terminating Apollo Link that will be used to send the request to the
   * GraphQL server.
   *
   * - See [Apollo Docs - The Terminating Link](https://www.apollographql.com/docs/react/api/link/introduction/#the-terminating-link)
   *
   * @default new HttpLink({ uri: api.uri })
   */
  terminatingLink?: ApolloLink
}

/**
 * Returns an Apollo Client that authenticates every query/mutation with the
 * user’s Auth0 token
 *
 * - See [Apollo Client documentation](https://www.apollographql.com/docs/react/api/core/ApolloClient)
 * - See [Apollo Client options](https://www.apollographql.com/docs/react/api/core/ApolloClient#options)
 *
 * NOTE: This hook can only be used within an `<Auth0Provider>`
 *
 * ```tsx
 * const client = useAuthenticatedApolloClient({
 *   api: {
 *     uri: 'https://api.example.com/graphql',
 *     audience: 'https://api.example.com',
 *     scope: 'read:users',
 *   },
 * })
 *
 * client.query(…) // (will be authenticated)
 * ```
 */
export const useAuthenticatedApolloClient = ({
  api: { uri, audience, scope },
  clientOptions: mutableClientOptions = {},
  terminatingLink: terminatingLinkOverride,
}: AuthenticatedApolloClientHookOptions) => {
  // HACK: We should re-render if any `clientOptions` change, but that would
  // require a lot of complex memoization work. Instead we create an immutable
  // copy on initial render.
  const [clientOptions] = useState(mutableClientOptions)

  const { getAccessTokenWithRedirect } = useAuthHelpers()

  const client = useMemo(() => {
    const authMiddleware = setContext(async (_request, { headers = {} }) => {
      const token = await getAccessTokenWithRedirect({ audience, scope })

      return {
        headers: {
          ...headers,
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      }
    })

    const terminatingLink = terminatingLinkOverride ?? new HttpLink({ uri })

    return new ApolloClient({
      cache: new InMemoryCache(),
      ...clientOptions,

      link: ApolloLink.from([
        ...(clientOptions?.link ? [clientOptions.link] : []),
        authMiddleware,
        terminatingLink,
      ]),
    })
  }, [audience, clientOptions, getAccessTokenWithRedirect, scope, terminatingLinkOverride, uri])

  return client
}
