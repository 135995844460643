import { Box, Fade } from '@mui/material'

import {
  Loading as ArcLoading,
  LoadingProps as ArcLoadingProps,
} from '@assuranceiq/react-components'

import type { StandardLonghandProperties } from 'csstype'

export const DEFAULT_DELAY = '500ms'

export interface LoadingProps extends ArcLoadingProps {
  /**
   * Delay before showing the loading spinner
   *
   * @default '500ms'
   */
  // TODO: Move to ARC?
  optimisticDelay?: StandardLonghandProperties['transitionDelay'] | boolean
}

/**
 * Displays a loading spinner, with optional “optimistic” delay
 */
export const Loading = ({ optimisticDelay, ...props }: LoadingProps) => {
  if (!optimisticDelay) return <ArcLoading {...props} />

  const transitionDelay = typeof optimisticDelay === 'string' ? optimisticDelay : DEFAULT_DELAY

  return (
    <Fade in style={{ transitionDelay }} timeout={800}>
      <Box>
        <ArcLoading {...props} />
      </Box>
    </Fade>
  )
}
