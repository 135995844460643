// IMPORTANT: !!!!DO NOT CHANGE THE FILE EXTENSION!!!!
// This file has a .tsx extension so Storybook can read/interpret its JSDocs
// SEE: https://github.com/storybookjs/storybook/discussions/15512#discussioncomment-1032862

import { useEffect, useMemo, useState } from 'react'

import { DataProvider } from 'react-admin'
import buildHasuraProvider, { FetchType } from 'ra-data-hasura'
import { IntrospectionResult } from 'ra-data-hasura/dist/types'

import { buildCustomFields } from './buildCustomFields'
import { buildCustomRequestHandler } from './buildCustomRequestHandler'
import { introspectHasuraSchema } from './introspectHasuraSchema'
import { HasuraDataProviderHook } from './useHasuraDataProvider.types'

/**
 * Creates a Hasura Data Provider for React Admin
 *
 * Wraps and instantiates the dataProvider helper from `ra-data-hasura`.
 *
 * **NOTE:** The provider is only instantiated once, on first render. Any
 * changes to configuration will require a browser refresh to propagate (i.e.
 * hot-reloading won’t work)
 *
 * - See [DataProvider documentation](https://marmelab.com/react-admin/DataProviderIntroduction.html)
 * - See [ra-data-hasura documentation](https://github.com/hasura/ra-data-hasura)
 *
 * ```tsx
 * const dataProvider = useHasuraDataProvider({
 *   api,    // e.g. from your console config
 *   client, // e.g. from useAuthenticatedApolloClient()
 *   customQueries, // See customQueries prop documentation
 * })
 *
 * if (!dataProvider) return <Loading fullscreen fullscreenTitle='Setting up data provider…' />
 *
 * return (
 *   <Admin dataProvider={dataProvider}>
 *     …
 *   </Admin>
 * )
 * ```
 */
export const useHasuraDataProvider: HasuraDataProviderHook = (mutableOptions) => {
  // HACK: We should re-render if any `options` change, but that would require a
  // lot of complex memoization work. Instead we create an immutable copy on
  // initial render.
  const [options] = useState(mutableOptions)

  const [defaultDataProvider, setDefaultDataProvider] = useState<DataProvider>()
  const [introspection, setIntrospection] = useState<IntrospectionResult>()

  useEffect(() => {
    const {
      client,
      customQueries,
      hasuraProviderOptions,
      buildGraphqlQueryOverrides,
      buildVariables,
      getResponseParser,
    } = options

    const introspectionOptions = hasuraProviderOptions?.introspection
    if (introspectionOptions === false) throw new Error('Introspection is required')

    const buildDataProvider = async () => {
      const introspectionResults = await introspectHasuraSchema(client, introspectionOptions)

      const dataProvider = await buildHasuraProvider(
        {
          client,
          introspection: { ...introspectionOptions, ...introspectionResults },
          ...hasuraProviderOptions,
        },
        {
          buildFields: buildCustomFields(customQueries),
          ...buildGraphqlQueryOverrides,
        },
        buildVariables,
        getResponseParser,
      )

      setIntrospection(introspectionResults)
      setDefaultDataProvider(dataProvider)
    }

    buildDataProvider()
  }, [options])

  const customDataProvider = useMemo<DataProvider | undefined>(() => {
    if (!defaultDataProvider) return
    if (!introspection) return

    const requestHandler = buildCustomRequestHandler(defaultDataProvider, introspection, options)

    return {
      ...defaultDataProvider,

      getList: requestHandler(FetchType.GET_LIST),
      getOne: requestHandler(FetchType.GET_ONE),
      getMany: requestHandler(FetchType.GET_MANY),
      getManyReference: requestHandler(FetchType.GET_MANY_REFERENCE),
      create: requestHandler(FetchType.CREATE),
      update: requestHandler(FetchType.UPDATE),
      updateMany: requestHandler(FetchType.UPDATE_MANY),
      delete: requestHandler(FetchType.DELETE),
      deleteMany: requestHandler(FetchType.DELETE_MANY),
    }
  }, [defaultDataProvider, introspection, options])

  return customDataProvider
}
