import { stripIndent } from 'common-tags'
import { invert } from 'lodash-es'

import { ConsoleConfig } from 'lib/consoleConfigs'

import {
  CRUD_PERMISSION_REGEX,
  REACT_ADMIN_ACTION_CONVERSIONS,
  REACT_ADMIN_ALL_ALLOWED,
} from './constants'
import {
  AssurancePermission,
  ReactAdminPermissions,
  ReactAdminPermissionsMap,
} from './permissionsModel.types'

/**
 * Converts Assurance Permission strings into React Admin Permissions
 *
 * See [ra-rbac](https://marmelab.com/ra-enterprise/modules/ra-rbac#concepts)
 * for expected format
 */
export const convertAssurancePermissionsToReactAdminPermissions = (
  newPermissions: Set<AssurancePermission>,
): ReactAdminPermissionsMap => {
  let resourceIndex = -1

  const newReactAdminPermissions = [...newPermissions].reduce((permissions, permission) => {
    const matches = permission.match(CRUD_PERMISSION_REGEX)

    // Ignore non-CRUD permissions
    if (!matches?.groups) return permissions

    const { service, action, resource } = matches.groups

    // Set up a new service
    permissions[service] ||= []
    if (permissions[service].length === 0) resourceIndex = -1

    // Set up a new resource
    if (permissions[service][resourceIndex]?.resource !== resource) {
      resourceIndex++
      permissions[service][resourceIndex] = { resource, action: [] }
    }

    // Add the action and any React Admin equivalents
    const actions = permissions[service][resourceIndex].action as string[]
    actions.push(action, ...(REACT_ADMIN_ACTION_CONVERSIONS[action] || []))

    return permissions
  }, {} as ReactAdminPermissionsMap)

  return newReactAdminPermissions
}

export const reactAdminPermissionsForConsole = (
  reactAdminPermissionsMap: ReactAdminPermissionsMap,
  consoleConfig: ConsoleConfig,
): ReactAdminPermissions => {
  const {
    permissions: { hasResourceCRUDPermissions },
    apis,
  } = consoleConfig

  if (!hasResourceCRUDPermissions) return REACT_ADMIN_ALL_ALLOWED

  const consoleReactAdminPermissions = Object.values(apis).reduce((permissions, api) => {
    if (!api.permissions?.namespace)
      throw new Error(stripIndent`
        API config for "${api.audience}" does not have a permissions namespace defined.
        A namespace is required for resource CRUD permissions.
      `)

    const permissionsForAPI = reactAdminPermissionsMap[api.permissions.namespace] ?? []

    // Support resource aliases
    const aliasesByResource = invert(api.resourceAliases ?? {})
    permissionsForAPI.forEach((permission) => {
      permission.resource = aliasesByResource[permission.resource] || permission.resource
    })

    return permissions.concat(permissionsForAPI)
  }, [] as ReactAdminPermissions)

  return consoleReactAdminPermissions
}
