import { ActionString, ReactAdminPermissions } from './permissionsModel.types'

export const PERMISSION_REGEX = /^(?<service>[^.]+)\.(?<resource>[^.]+)\.(?<action>[^.]+)$/
export const CRUD_PERMISSION_REGEX =
  /^(?<service>[^.]+)\.(?<resource>[^.]+)\.(?<action>(?:create|read|update|delete))$/

export const REACT_ADMIN_ALL_ALLOWED: ReactAdminPermissions = [{ action: '*', resource: '*' }]

/** Converts Assurance CRUD actions into React Admin actions */
export const REACT_ADMIN_ACTION_CONVERSIONS: Record<ActionString, ActionString[] | undefined> = {
  read: ['list', 'show'],
  update: ['edit'],
}
