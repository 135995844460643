import { lazy } from 'react'

import FingerprintIcon from '@mui/icons-material/Fingerprint'

import { ConsoleConfig, ConsoleID } from 'lib/consoleConfigs'

import {
  IDENTITY_SERVICE_API_ENDPOINT,
  IDENTITY_SERVICE_AUTH0_AUDIENCE,
} from '../utils/environment-variables'

export const identityServiceConfig = {
  id: ConsoleID.IdentityService,

  name: 'Identity Service',
  Icon: FingerprintIcon,
  description: 'The single source of truth for identity data',

  path: '/identity-service',
  Console: lazy(() => import('consoles/identity-service')),

  apis: {
    identityService: {
      uri: IDENTITY_SERVICE_API_ENDPOINT,
      audience: IDENTITY_SERVICE_AUTH0_AUDIENCE,
      permissions: {
        namespace: 'identity_service',
      },
    },
  },

  permissions: {
    rolePrefix: 'identity_service',
    hasResourceCRUDPermissions: false,
  },

  team: {
    name: 'IAP',
    href: 'https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/2039776201/Identity+Access+Platform+IAP',
  },
} satisfies ConsoleConfig
