import { AppBar, AppBarProps, Link } from 'react-admin'

import { Stack, Typography } from '@mui/material'

import { clsx } from 'clsx'

import logo from 'images/assurance-logo.svg'

import { EnvironmentChip } from './EnvironmentChip'

import styles from './ConsoleHeader.module.scss'

export const ConsoleHeader = ({ className, ...props }: AppBarProps) => (
  <AppBar {...props} className={clsx(styles.header, className)}>
    <Stack direction='row' spacing={2} className={styles.stack}>
      <Typography variant='h6' className={styles.logo}>
        <Link to='/'>
          <img src={logo} alt='Assurance Logo' />
          AIQ Console
        </Link>
      </Typography>
      <EnvironmentChip />
    </Stack>
  </AppBar>
)
