import { lazy } from 'react'

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

import { ConsoleConfig, ConsoleID } from 'lib/consoleConfigs'

import {
  PRODUCT_ELIGIBILITY_SERVICE_API_ENDPOINT,
  PRODUCT_ELIGIBILITY_SERVICE_AUTH0_AUDIENCE,
} from '../utils/environment-variables'

export const productEligibilityServiceConfig = {
  id: ConsoleID.ProductEligibilityService,

  name: 'Product Eligibility Service',
  Icon: PlaylistAddCheckIcon,
  description: 'Determine product eligibility',

  path: '/eligibility-service',
  Console: lazy(() => import('consoles/eligibility-service')),

  apis: {
    productEligibilityService: {
      uri: PRODUCT_ELIGIBILITY_SERVICE_API_ENDPOINT,
      audience: PRODUCT_ELIGIBILITY_SERVICE_AUTH0_AUDIENCE,
      permissions: {
        namespace: 'eligibility_service',
      },
    },
  },

  permissions: {
    rolePrefix: 'eligibility_service',
    hasResourceCRUDPermissions: false,
  },

  team: {
    name: 'CPS',
    href: 'https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/3217359205/Carrier+Platform+Services+CPS+Team',
  },
} satisfies ConsoleConfig
