import { IntrospectionOptions, introspectSchema as defaultIntrospectSchema } from 'ra-data-graphql'
import { FetchType } from 'ra-data-hasura'
import { IntrospectionResult as HasuraIntrospectionResult } from 'ra-data-hasura/dist/types'

import { ApolloClient } from '@apollo/client'
import { merge } from 'lodash-es'

// HACK: Copied from ra-data-hasura (they unfortunately don’t export `defaultOptions`)
// It’s unlikely that this would change
// SEE: https://github.com/hasura/ra-data-hasura/blob/fa52b44bfb7d617e768b60718a52d45ca79d5789/src/customDataProvider/index.ts#L35-L49
export const defaultIntrospectionOptions: IntrospectionOptions = {
  operationNames: {
    [FetchType.GET_LIST]: (resource) => resource.name,
    [FetchType.GET_ONE]: (resource) => resource.name,
    [FetchType.GET_MANY]: (resource) => resource.name,
    [FetchType.GET_MANY_REFERENCE]: (resource) => resource.name,
    [FetchType.CREATE]: (resource) => `insert_${resource.name}`,
    [FetchType.UPDATE]: (resource) => `update_${resource.name}`,
    [FetchType.UPDATE_MANY]: (resource) => `update_${resource.name}`,
    [FetchType.DELETE]: (resource) => `delete_${resource.name}`,
    [FetchType.DELETE_MANY]: (resource) => `delete_${resource.name}`,
  },
}

export const introspectHasuraSchema = async (
  client: ApolloClient<unknown>,
  options?: Partial<IntrospectionOptions>,
): Promise<HasuraIntrospectionResult> => {
  const mergedOptions = merge({}, defaultIntrospectionOptions, options)
  const result = await defaultIntrospectSchema(client, mergedOptions)

  return result as HasuraIntrospectionResult
}
