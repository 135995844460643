import { useState } from 'react'

import Close from '@mui/icons-material/Close'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { Chip, ChipProps } from '@mui/material'

import { DEPLOY_ENV, DeployEnv, DISABLE_ENV_CHIP } from 'lib/environment-variables'

const ENV_COLOR: Record<DeployEnv, ChipProps['color']> = {
  development: 'success',
  preview: 'info',
  integration: 'warning',
  production: 'error',
}

// Extracts BUILDKITE variables from the URL.
// Must match pattern in `/.buildkite/pipeline.js` `PREVIEW_APP.domain`
const PREVIEW_URL = /(?<pipeline>[\w-_]+)--(?<build_id>\d+)--/

export const EnvironmentChip = () => {
  const [open, setOpen] = useState(true)

  if (DEPLOY_ENV === DeployEnv.PRODUCTION || DISABLE_ENV_CHIP || !open) return null

  const { pipeline, build_id } = window.location.origin.match(PREVIEW_URL)?.groups ?? {}
  const previewProps =
    DEPLOY_ENV === DeployEnv.PREVIEW && pipeline && build_id
      ? {
          component: 'a',
          clickable: true,
          icon: <OpenInNew />,
          href: `https://buildkite.com/assuranceiq/${pipeline}/builds/${build_id}`,
          target: '_blank',
        }
      : {}

  return (
    <Chip
      label={DEPLOY_ENV}
      size='small'
      color={ENV_COLOR[DEPLOY_ENV]}
      onDelete={() => setOpen(false)}
      deleteIcon={<Close />}
      {...previewProps}
    />
  )
}
