import { buildFields as defaultBuildFields, BuildFields } from 'ra-data-hasura'

import { codeBlock, stripIndent } from 'common-tags'
import { DocumentNode, FieldNode, Kind } from 'graphql'

import { CustomQueries } from './useHasuraDataProvider.types'

/**
 * Constructs a custom query fields builder
 *
 * @see https://github.com/hasura/ra-data-hasura#example-write-a-completely-custom-query
 *
 * @example
 *   // returns a build field method that uses custom fields for the users list
 *   // and defaults for everything else
 *   buildCustomFields({
 *     users: {
 *       GET_LIST: {
 *         fields: gql`{ id, name }`
 *       }
 *     }
 *   })
 */
export const buildCustomFields =
  (customQueries?: CustomQueries): BuildFields =>
  (type, fetchType) => {
    const resourceName = type.name
    const customQuery = fetchType && customQueries?.[resourceName]?.[fetchType]?.fields

    return customQuery ? extractFieldsFromQuery(customQuery) : defaultBuildFields(type, fetchType)
  }

/**
 * Extracts fields from a Graphql query
 *
 * Inspired by ra-data-hasura’s example
 * @see https://github.com/hasura/ra-data-hasura/blob/fa52b44/README.md?plain=1#L399-L405
 */
export const extractFieldsFromQuery = (queryAst: DocumentNode): FieldNode[] => {
  const operationDefinitionNode = queryAst.definitions[0]

  if (operationDefinitionNode?.kind !== Kind.OPERATION_DEFINITION) {
    throw new Error(codeBlock`
      Custom field query doesn’t match the expected format; e.g.

      gql\`{
        id
        field1
        field2
      }\`

      See https://github.com/hasura/ra-data-hasura#example-write-a-completely-custom-query.

      Query given:

      gql\`
        ${stripIndent(queryAst.loc?.source.body || '[UNABLE TO DETECT QUERY SOURCE]')}
      \`
    `)
  }

  return operationDefinitionNode.selectionSet.selections as FieldNode[]
}
