import { lazy } from 'react'

import HandshakeIcon from '@mui/icons-material/Handshake'

import { ConsoleConfig, ConsoleID } from 'lib/consoleConfigs'

import { AFFIX_API_ENDPOINT, AFFIX_AUTH0_AUDIENCE } from '../utils/environment-variables'

export const affixConfig = {
  id: ConsoleID.Affix,

  name: 'Affix',
  Icon: HandshakeIcon,
  description: 'Manages affiliate marketing offers and configurations',

  path: '/affix',
  Console: lazy(() => import('consoles/affix')),

  apis: {
    affix: {
      uri: AFFIX_API_ENDPOINT,
      audience: AFFIX_AUTH0_AUDIENCE,
      permissions: {
        namespace: 'affix',
      },
    },
  },

  permissions: {
    rolePrefix: 'affix',
    hasResourceCRUDPermissions: true,
  },

  team: {
    name: 'M&M - Marketplace & Marketing',
    href: 'https://assuranceiq.atlassian.net/wiki/spaces/MM/overview?homepageId=3010495737',
  },
} satisfies ConsoleConfig
