import { HttpError } from 'react-admin'
import { buildQuery, FetchType } from 'ra-data-hasura'

import { ApolloError, MutationOptions, QueryOptions } from '@apollo/client'

import {
  BuildCustomRequestHandler,
  DefaultQueryBuilder,
  DefaultQueryBuilderResult,
  RequestHandler,
} from './buildCustomRequestHandler.types'
import { HasuraDataProviderMeta } from './useHasuraDataProvider.types'

export const requestMethods = {
  [FetchType.GET_LIST]: 'getList',
  [FetchType.GET_ONE]: 'getOne',
  [FetchType.GET_MANY]: 'getMany',
  [FetchType.GET_MANY_REFERENCE]: 'getManyReference',
  [FetchType.CREATE]: 'create',
  [FetchType.UPDATE]: 'update',
  [FetchType.UPDATE_MANY]: 'updateMany',
  [FetchType.DELETE]: 'delete',
  [FetchType.DELETE_MANY]: 'deleteMany',
}

export const buildCustomRequestHandler: BuildCustomRequestHandler =
  (defaultDataProvider, introspection, options) => (fetchType) => (resource, params) =>
    handleRequest({
      defaultDataProvider,
      introspection,
      options,
      fetchType,
      resource,
      params,
    })

/**
 * Handles any DataProvider request (e.g. `GET_LIST`, `GET_ONE`, `DELETE`, etc)
 * and uses custom queries if they match the request.
 *
 * See HasuraDataProviderHookOptions['customQueries'] for more info
 */
export const handleRequest: RequestHandler = async ({
  defaultDataProvider,
  introspection,
  options,
  fetchType,
  resource: resourceOrAlias,
  params,
}) => {
  const requestMethod = requestMethods[fetchType]
  const { api, client, customQueries } = options

  // Grab the actual resource name in case `resource` is a custom alias
  const resource = api.resourceAliases?.[resourceOrAlias] || resourceOrAlias

  const customRequestHandler =
    customQueries?.[resourceOrAlias]?.[fetchType]?.query ||
    (params.meta as HasuraDataProviderMeta<typeof fetchType>)?.query

  if (!customRequestHandler) return await defaultDataProvider[requestMethod](resource, params)

  try {
    const { parseResponse, clientOptions } = await buildDefaultQuery(fetchType, {
      introspection,
      resource,
      params,
    })

    const result = await customRequestHandler({
      client,
      clientOptions,
      resource,
      params,
      parseResponse,
    })

    return result
  } catch (error) {
    handleError(error)
  }
}

export const buildDefaultQuery: DefaultQueryBuilder = async (
  fetchType,
  { introspection, resource, params },
) => {
  const { parseResponse, query, variables } = buildQuery(introspection)(fetchType, resource, params)

  switch (fetchType) {
    case FetchType.CREATE:
    case FetchType.UPDATE:
    case FetchType.UPDATE_MANY:
    case FetchType.DELETE:
    case FetchType.DELETE_MANY: {
      return {
        parseResponse,
        clientOptions: {
          mutation: query,
          variables,
        } as MutationOptions,
      } as DefaultQueryBuilderResult<typeof fetchType>
    }

    case FetchType.GET_LIST:
    case FetchType.GET_ONE:
    case FetchType.GET_MANY:
    case FetchType.GET_MANY_REFERENCE:
    default:
      return {
        parseResponse,
        clientOptions: {
          query,
          variables,

          // SEE: https://github.com/marmelab/react-admin/blob/master/packages/ra-data-graphql/README.md#when-i-create-or-edit-a-resource-the-list-or-edit-page-does-not-refresh-its-data
          fetchPolicy: 'network-only',
        } as QueryOptions,
      } as DefaultQueryBuilderResult<typeof fetchType>
  }
}

/**
 * Converts errors to HttpError for React Admin to parse
 *
 * Modified from ra-data-graphql
 * @see https://github.com/marmelab/react-admin/blob/1461ad3bc9779bbdddf9804fbcf36d922bf78d56/packages/ra-data-graphql/src/index.ts#L223-L232
 */
export const handleError = (error: unknown) => {
  if (!(error instanceof ApolloError)) throw error

  const { networkError } = error

  if (networkError) {
    throw new HttpError(
      networkError.message,
      'statusCode' in networkError ? networkError.statusCode : null,
      networkError,
    )
  }

  throw new HttpError(error.message, 200, error)
}
