// IMPORTANT: !!!!DO NOT CHANGE THE FILE EXTENSION!!!!
// This file has a .tsx extension so Storybook can read/interpret its JSDocs
// SEE: https://github.com/storybookjs/storybook/discussions/15512#discussioncomment-1032862

import { useMemo } from 'react'

import { AuthProvider } from 'react-admin'

import { ApolloError } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

import { useAuthHelpers } from 'hooks/useAuthHelpers'
import { useConsoleConfig } from 'hooks/useConsoleConfig'
import { useStateChangePromise } from 'hooks/useStateChangePromise'
import { useStore, useStoreState } from 'lib/store'

/**
 * Returns a React Admin Auth Provider, configured for Auth0
 *
 * - See [Auth Providers documentation](https://marmelab.com/react-admin/Authentication.html)
 *
 * NOTE: This hook can only be used within an `<Auth0Provider>`
 *
 * ```tsx
 * const authProvider = useAuth0AuthProvider()
 *
 * return (
 *   <Admin authProvider={authProvider}>
 *     …
 *   </Admin>
 * )
 * ```
 */
export const useAuth0AuthProvider = () => {
  const { isAuthenticated, user, logout: auth0Logout } = useAuth0()
  const store = useStore()
  const currentConsole = useConsoleConfig()
  const reactAdminPermissions = useStoreState((state) =>
    state.permissions.reactAdminPermissions(currentConsole),
  )

  const { fetchAndUpdatePermissions } = useAuthHelpers()

  const definedReactAdminPermissionsPromise = useStateChangePromise(
    reactAdminPermissions,
    (permissions) => typeof permissions !== 'undefined',
  )

  const authProvider = useMemo<AuthProvider>(
    () => ({
      // This method never gets called directly—login is handled by Auth0Provider
      async login() {
        return
      },

      async logout() {
        await store.persist.clear()
        await auth0Logout({
          returnTo: window.location.origin,
        })
      },

      /** when the user navigates, make sure that their credentials are still valid */
      async checkAuth() {
        if (isAuthenticated) return

        throw new Error('Not Authenticated')
      },

      /**
       * when the dataProvider returns an error, check if this is an authentication
       * error (meaning we need to login)
       */
      async checkError(error) {
        if (!(error instanceof ApolloError)) return

        for (const err of error.graphQLErrors) {
          const errorCode = String(err.extensions?.code).trim()
          switch (errorCode) {
            case 'UNAUTHENTICATED':
            case 'FORBIDDEN':
              throw error
            default:
              return
          }
        }
      },

      /** get the user’s profile */
      async getIdentity() {
        return {
          id: user?.id,
          fullName: user?.name,
          avatar: user?.picture,
        }
      },

      /** get the user’s permissions */
      async getPermissions() {
        await Promise.all(
          Object.values(currentConsole.apis).map((api) =>
            fetchAndUpdatePermissions({
              audience: api.audience,
              scope: api.scope,
            }),
          ),
        )

        const permissions = await definedReactAdminPermissionsPromise()
        return permissions
      },
    }),
    [
      auth0Logout,
      currentConsole,
      definedReactAdminPermissionsPromise,
      isAuthenticated,
      store,
      fetchAndUpdatePermissions,
      user?.id,
      user?.name,
      user?.picture,
    ],
  )

  return authProvider
}
