// IMPORTANT: !!!!DO NOT CHANGE THE FILE EXTENSION!!!!
// This file has a .tsx extension so Storybook can read/interpret its JSDocs
// SEE: https://github.com/storybookjs/storybook/discussions/15512#discussioncomment-1032862

import { useCallback } from 'react'

import { GetTokenSilentlyOptions, OAuthError, useAuth0 } from '@auth0/auth0-react'

import { useStoreActions, useStoreState } from 'lib/store'
import { AssurancePermissions } from 'lib/store/models/permissions'

export type GetAccessTokenOptions = GetTokenSilentlyOptions

/** Auto redirect if we encounter these OAuth errors */
const ERRORS_TO_REDIRECT = ['login_required', 'consent_required']

/**
 * Provides custom helpers for working with Auth0 in the AIQ Console environment
 *
 * ```ts
 * const { getAccessTokenWithRedirect, fetchAndUpdatePermissions } = useAuthHelpers()
 *
 * const token = await getAccessTokenWithRedirect({ audience: 'https://api.example.com' })
 *
 * await fetchAndUpdatePermissions({ audience: 'https://api.example.com' })
 * ```
 */
export const useAuthHelpers = () => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0()
  const updatePermissions = useStoreActions((actions) => actions.permissions.upsert)
  const addKnownToken = useStoreActions((actions) => actions.authTokens.add)
  const knownTokens = useStoreState((state) => state.authTokens.tokens)

  const getAccessTokenWithRedirect = useCallback(
    async (options: GetAccessTokenOptions) => {
      try {
        return await getAccessTokenSilently(options)
      } catch (error) {
        if (error instanceof OAuthError && ERRORS_TO_REDIRECT.includes(error.error)) {
          await loginWithRedirect({
            ...options,
            appState: { returnTo: window.location.href },
          })
        }

        throw error
      }
    },
    [getAccessTokenSilently, loginWithRedirect],
  )

  const fetchAndUpdatePermissions = useCallback(
    async (options: GetAccessTokenOptions) => {
      const { audience } = options

      // Auth0 uses opaque access tokens if no audience is given
      // SEE: https://auth0.com/docs/secure/tokens/access-tokens#opaque-access-tokens
      if (!audience) return

      const token = await getAccessTokenWithRedirect(options)

      if (knownTokens[audience] === token) return

      addKnownToken({ audience, token })

      const payloadBlob = token.split('.')[1]

      if (!payloadBlob) throw new Error('Unable to parse access token')
      const payload = JSON.parse(window.atob(payloadBlob))

      const permissions: AssurancePermissions = payload.permissions ?? []

      // FIXME: Remove this once RBAC is enabled for person service
      if (!permissions.length) {
        permissions.push(
          ...(payload['https://hasura.io/jwt/claims']?.['x-hasura-permissions']
            ?.replaceAll(/^\{|\}$/g, '')
            ?.split(',') || []),
        )
      }

      updatePermissions({ audience, permissions })
    },
    [addKnownToken, getAccessTokenWithRedirect, knownTokens, updatePermissions],
  )

  return {
    /**
     * If there’s a valid token stored, returns it. Otherwise fetches one. Will
     * redirect if the user needs to log in/grant consent.
     *
     * ```ts
     * const token = await getAccessTokenWithRedirect(options);
     * ```
     */
    getAccessTokenWithRedirect,

    /**
     * Fetches permissions for the given audience and scope, and updates the
     * store with the new permissions.
     */
    fetchAndUpdatePermissions,
  } as const
}
