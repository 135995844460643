import { stripIndent } from 'common-tags'
import { action, Action, computed, Computed } from 'easy-peasy'
import { partition } from 'lodash-es'

import { ConsoleConfig } from 'lib/consoleConfigs'
import { JWTAudience } from 'lib/store/models/authTokens'

import { PERMISSION_REGEX } from './constants'
import {
  convertAssurancePermissionsToReactAdminPermissions,
  reactAdminPermissionsForConsole,
} from './convert-permissions'
import {
  AssurancePermission,
  AssurancePermissions,
  AssurancePermissionsMap,
  ReactAdminPermissions,
  ReactAdminPermissionsMap,
} from './permissionsModel.types'

export interface PermissionsModel {
  __assurancePermissionsMap: AssurancePermissionsMap
  assurancePermissions: Computed<PermissionsModel, Set<AssurancePermission>>

  __reactAdminPermissionsMap: Computed<PermissionsModel, ReactAdminPermissionsMap>
  reactAdminPermissions: Computed<
    PermissionsModel,
    (consoleConfig: ConsoleConfig) => ReactAdminPermissions
  >

  upsert: Action<PermissionsModel, { audience: JWTAudience; permissions: AssurancePermissions }>
}

export const permissionsModel: PermissionsModel = {
  __assurancePermissionsMap: {},
  assurancePermissions: computed(
    (state) => new Set(Object.values(state.__assurancePermissionsMap).flat().sort()),
  ),

  __reactAdminPermissionsMap: computed((state) =>
    convertAssurancePermissionsToReactAdminPermissions(state.assurancePermissions),
  ),
  reactAdminPermissions: computed(
    (state) => (consoleConfig) =>
      reactAdminPermissionsForConsole(state.__reactAdminPermissionsMap, consoleConfig),
  ),

  upsert: action((state, payload) => {
    const [newPermissions, invalidPermissions] = partition(payload.permissions, (permission) =>
      PERMISSION_REGEX.test(permission),
    )

    if (invalidPermissions.length > 0) {
      console.error(stripIndent`
        Invalid permissions: ${JSON.stringify(invalidPermissions)}
        Expected format: ${PERMISSION_REGEX.source}
      `)
    }

    state.__assurancePermissionsMap = {
      ...state.__assurancePermissionsMap,
      [payload.audience]: newPermissions,
    }
  }),
}
