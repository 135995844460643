import { FC } from 'react'

import { Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_RESPONSE_TYPE } from 'lib/environment-variables'

export const Auth0WithReactRouterIntegrationProvider: FC = ({ children }) => {
  const navigate = useNavigate()

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.document.location.origin}
      responseType={AUTH0_RESPONSE_TYPE}
      onRedirectCallback={(appState) => {
        const path = (appState?.returnTo || window.location.href).replace(
          window.location.origin,
          '',
        )
        navigate(path)
      }}
      // HACK: Can’t use cookie auth for local development, so we use refresh tokens and localstorage instead
      useRefreshTokens={process.env.NODE_ENV === 'development'}
      cacheLocation={process.env.NODE_ENV === 'development' ? 'localstorage' : 'memory'}
    >
      {children}
    </Auth0Provider>
  )
}
