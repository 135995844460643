import { createStore, createTypedHooks, EasyPeasyConfig, persist, State } from 'easy-peasy'

import { authTokensModel, AuthTokensModel } from './models/authTokens'
import { permissionsModel, PermissionsModel } from './models/permissions'

export interface StoreModel {
  authTokens: AuthTokensModel
  permissions: PermissionsModel
}

export type StoreState = State<StoreModel>
export type AIQConsoleStoreConfig = EasyPeasyConfig<StoreState>

export const model: StoreModel = {
  authTokens: authTokensModel,
  permissions: persist(permissionsModel),
}

/**
 * Useful for creating a mocked store with custom injections or mockActions enabled
 *
 * @see https://easy-peasy.dev/docs/api/store-config.html#standard-configuration
 * @see https://easy-peasy.dev/docs/tutorials/testing.html#strategy-1-mocking-actions
 * @see https://easy-peasy.dev/docs/tutorials/testing.html#mocking-calls-to-services
 */
export const createAIQConsoleStore = (config: AIQConsoleStoreConfig = {}) =>
  createStore<StoreModel, StoreState>(model, {
    ...config,

    // Increment this after major refactors to clear persisted state
    // SEE: https://easy-peasy.dev/docs/api/persist.html#managing-model-updates
    version: 1,
  })

export const store = createAIQConsoleStore()

export const { useStore, useStoreActions, useStoreDispatch, useStoreState } =
  createTypedHooks<StoreModel>()
