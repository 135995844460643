import { defaultTheme as reactAdminTheme, RaThemeOptions } from 'react-admin'

import { createAssuranceTheme } from '@assuranceiq/react-components'
import Colors from '@assuranceiq/react-components/styles/colors/_colors.scss'

// TODO: Move to ARC
import '@fontsource/open-sans/variable.css'

// HACK: Add React Admin options to default ThemeOptions
declare module '@mui/material/styles' {
  interface ThemeOptions {
    sidebar?: RaThemeOptions['sidebar']
  }
}

let theme = createAssuranceTheme({
  // TODO: Move to ARC
  // prettier-ignore
  typography: {
    fontFamily: [
      '"Open SansVariable"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    xxxl:   { fontSize: '54px', lineHeight: 1.25, marginBottom: '.75em' },
    xxl:    { fontSize: '36px', lineHeight: 1.25, marginBottom: '.75em' },
    xl:     { fontSize: '24px', lineHeight: 1.25, marginBottom: '.75em' },
    large:  { fontSize: '20px', lineHeight: 1.25, marginBottom: '.75em' },
    medium: { fontSize: '18px', lineHeight: 1.25, marginBottom: '.75em' },
    body:   { fontSize: '16px', lineHeight: 1.25, marginBottom: '.75em' },
  },

  components: {
    MuiTypography: {
      defaultProps: {
        // prettier-ignore
        variantMapping: {
          xxxl:   'h1',
          xxl:    'h1',
          xl:     'h1',
          large:  'h2',
          medium: 'h3',
          body:   'p',
        },
      },
    },
  },
})

theme = createAssuranceTheme({
  ...reactAdminTheme,
  ...theme,

  components: {
    ...reactAdminTheme.components,
    ...theme.components,

    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },

    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          color: Colors['gray-75'],
        },
      },
    },

    // @ts-ignore React-admin components are not typed

    RaDatagrid: {
      styleOverrides: {
        // TODO: Update default table styles in ARC
        root: {
          '.RaDatagrid-table': {
            borderCollapse: 'inherit', // Fixes styling of sticky header border
          },
          '.RaDatagrid-row': {
            '.MuiTableCell-root': {
              borderBottom: `1px solid ${Colors['border-light']}`,
            },
            '&:not(.RaDatagrid-headerRow):last-child .MuiTableCell-root': {
              borderBottom: 'none',
            },
            '.RaDatagrid-headerCell': {
              backgroundColor: Colors['bg-light'],
              fontWeight: theme.typography.fontWeightBold,
              color: Colors['gray-100'],
            },
          },
        },
      },
    },

    RaMenuItem: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
  },

  sidebar: {
    closedWidth: 56, // 56 provides even padding on either side of sidebar icons
  },
})

export default theme
