import { LazyExoticComponent, VoidFunctionComponent } from 'react'

import { FetchType } from 'ra-data-hasura'

import type { SvgIconComponent } from '@mui/icons-material/index'

import { GetTokenSilentlyOptions } from '@auth0/auth0-react'

import { HasuraDataProviderMeta } from 'hooks/useHasuraDataProvider'
import { ResourceName } from 'types/react-admin.types'

export type ConsoleComponent = VoidFunctionComponent

export enum ConsoleID {
  Affix,
  CommissionsOperationsPortal,
  IdentityService,
  PersonService,
  PFTM,
  ProductCatalog,
  ProductEligibilityService,
}

export interface ConsoleConfig {
  id: ConsoleID

  name: string
  Icon: SvgIconComponent
  description: string

  path: string
  Console: LazyExoticComponent<ConsoleComponent>

  /** All APIs this console requires access to */
  apis: APIsConfig

  permissions: ConsolePermissionsConfig

  /** Team responsible for this console */
  team: TeamConfig
}

export type APIsConfig = Record<string, APIConfig>

export interface APIConfig {
  uri: string

  audience: NonNullable<GetTokenSilentlyOptions['audience']>
  scope?: GetTokenSilentlyOptions['scope']

  permissions?: APIPermissionsConfig

  /**
   * Custom aliases for resource names. This is mostly useful for supporting
   * custom routes for a given resource.
   *
   * Format: `{ [alias]: 'resource_name' }`
   *
   * > NOTE: This feature requires a dataProvider that supports aliases, like the
   * one provided by the `useHasuraDataProvider` hook. If using a different
   * dataProvider, you may need to implement your own alias support.
   *
   * For example, to set up a resource view for the `legacy_users_v1_final`
   * table, but at `/users`:
   *
   * ```tsx
   * // myConsoleConfig.ts
   * const consoleConfig = {
   *   resourceAliases: { users: 'legacy_users_v1_final' },
   * }
   *
   * // MyConsole.tsx
   * return (
   *   <ConsoleAdmin>
   *     { // Renders the `legacy_users_v1_final` table at `/users` }
   *     <Resource name='users' {...usersPages} />
   *   </ConsoleAdmin>
   * )
   * ```
   */
  resourceAliases?: ResourceAliases
}

/**
 * Custom aliases for resource names.
 *
 * Format: `{ [alias]: 'resource_name' }`
 *
 * @see {@link APIConfig.resourceAliases}
 */
export type ResourceAliases = Record<string, ResourceName>

export interface APIPermissionsConfig {
  /**
   * Permissions namespace
   *
   * The namespace of the permissions for this API. For example, a permission
   * of `person_service.people.read` would have a namespace of `person_service`.
   *
   * A namespace is required if a console has resource CRUD permissions enabled
   *
   * @see {@link ConsolePermissionsConfig.hasResourceCRUDPermissions}
   */
  namespace?: string
}

export interface ConsolePermissionsConfig {
  /**
   * Permissions role prefix
   *
   * We inspect the current user’s assigned Auth0 roles to determine if they
   * have access to a given console. For example, a role of `person_service.admin`
   * would match the namespace of `person_service`.
   *
   * See https://manage.auth0.com/ (User Management > Roles) for current roles
   *
   * NOTE: If undefined, we assume all users have access to this console
   *
   */
  rolePrefix?: string

  /**
   * Does this console’s APIs have CRUD permissions for every resource?
   * e.g. `my_service.resource_name.[create|read|update|delete]`.
   *
   * Setting this to `true` will enable `ra-rbac`, which will automatically
   * restrict resource access based on a user’s permissions.
   *
   * - See [AIQ Console - Permissions (confluence)](https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/3181412652/AIQ+Console+-+Permissions)
   * - See [`ra-rbac` documentation](https://marmelab.com/ra-enterprise/modules/ra-rbac)
   */
  hasResourceCRUDPermissions: boolean
}

export interface TeamConfig {
  /** Team name, e.g. "Platform Services" */
  name: string

  /** A link to contact/about info for the team (e.g. Confluence page, slack channel, etc) */
  href: string
}

/**
 * @deprecated This type has been moved and renamed, please import the following:
 *
 * ```ts
 * import { HasuraDataProviderMeta } from 'hooks/useHasuraDataProvider'
 * ```
 */
// TODO: Remove this type alias after 4/1/23
export type AIQMeta<F extends FetchType> = HasuraDataProviderMeta<F>
