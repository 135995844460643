import { FC, lazy, Suspense, useEffect } from 'react'

import { AssuranceThemeProvider, DataDogRUMProvider } from '@assuranceiq/react-components'

import { StoreProvider } from 'easy-peasy'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Loading } from 'components/Loading'
import { ConsoleConfigProvider } from 'hooks'
import { consoleConfigs } from 'lib/consoleConfigs'
import {
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_SAMPLE_RATE,
  DD_SERVICE,
  DD_VERSION,
  DEPLOY_ENV,
  DeployEnv,
} from 'lib/environment-variables'
import { store } from 'lib/store'
import theme from 'styles/theme'

import { AppLayout } from './AppLayout'
import { Auth0WithReactRouterIntegrationProvider } from './Auth0WithReactRouterIntegrationProvider'
import { RequireAuthentication } from './RequireAuthentication'

const Homepage = lazy(() => import('./Homepage'))

export const App = () => {
  // Add environment to window title
  useEffect(() => {
    if (DEPLOY_ENV === DeployEnv.PRODUCTION) return

    window.document.title = `AIQ Console [${DEPLOY_ENV}]`
  }, [])

  return (
    <AllProviders>
      <RequireAuthentication
        onRedirecting={() => (
          <AppLayout>
            <Loading fullscreen fullscreenTitle='Logging in…' optimisticDelay />
          </AppLayout>
        )}
      >
        <Suspense
          fallback={
            <AppLayout>
              <Loading fullscreen fullscreenTitle='Loading page…' optimisticDelay />
            </AppLayout>
          }
        >
          <Routes>
            {Object.values(consoleConfigs).map((console) => {
              const { Console } = console
              return (
                <Route
                  key={console.id}
                  path={`${console.path}/*`}
                  element={
                    <ConsoleConfigProvider config={console}>
                      <Console />
                    </ConsoleConfigProvider>
                  }
                />
              )
            })}
            <Route path='*' element={<Homepage />} />
          </Routes>
        </Suspense>
      </RequireAuthentication>
    </AllProviders>
  )
}

const AllProviders: FC = ({ children }) => {
  return (
    <BrowserRouter>
      <StoreProvider store={store}>
        <Auth0WithReactRouterIntegrationProvider>
          <AssuranceThemeProvider theme={theme}>
            <DataDogRUMProvider
              applicationId={DD_APPLICATION_ID}
              clientToken={DD_CLIENT_TOKEN}
              service={DD_SERVICE}
              env={DEPLOY_ENV}
              sampleRate={DD_SAMPLE_RATE}
              trackInteractions={true}
              version={DD_VERSION}
            >
              {children}
            </DataDogRUMProvider>
          </AssuranceThemeProvider>
        </Auth0WithReactRouterIntegrationProvider>
      </StoreProvider>
    </BrowserRouter>
  )
}
