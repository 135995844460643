import { ReactNode } from 'react'

import { Container, ContainerProps, Grid } from '@mui/material'

import { AppHeader } from 'components/App/AppHeader'

interface AppLayoutProps {
  children?: ReactNode

  /** Horizontally & vertically center contents */
  centered?: boolean

  /**
   * Wrap contents within a MUI Container
   *
   * @see https://mui.com/material-ui/react-container/
   */
  contained?: boolean

  /** @see https://mui.com/material-ui/api/container/ */
  containerProps?: ContainerProps
}

export const AppLayout = ({ children, centered, contained, containerProps }: AppLayoutProps) => {
  return (
    <Grid container direction='column' sx={{ backgroundColor: 'common.white', minHeight: '100vh' }}>
      <Grid item>
        <AppHeader />
      </Grid>
      <Container
        {...containerProps}
        maxWidth={contained ? containerProps?.maxWidth : false}
        sx={{
          display: 'flex',
          position: 'relative',
          flexGrow: 1,
          flexDirection: 'column',

          ...(centered && {
            alignItems: 'center',
            justifyContent: 'center',
          }),

          ...containerProps?.sx,
        }}
      >
        {children}
      </Container>
    </Grid>
  )
}
