export const requireEnvVar = (name: string) => {
  const value = process.env[`REACT_APP_${name}`]

  if (typeof value === 'undefined')
    throw new Error(
      `Missing environment variable: \`REACT_APP_${name}\`. Make sure to add it to your .env file.`,
    )

  return value
}

/**
 * Possible values for the `DEPLOY_ENV` environment variable.
 *
 * @example
 *   // Check if on a preview build
 *   DEPLOY_ENV === DeployEnv.PREVIEW
 */
export enum DeployEnv {
  DEVELOPMENT = 'development',
  PREVIEW = 'preview',
  INTEGRATION = 'integration',
  PRODUCTION = 'production',
}

/**
 * The current deploy environment (e.g. `"integration"`)
 *
 * Note that this is different than `NODE_ENV`, which defines the current
 * build/compile environment (e.g. `"development"`/`"production"`)
 *
 * See [Webpack docs on `NODE_ENV`](https://webpack.js.org/guides/production/#specify-the-mode)
 */
export const DEPLOY_ENV = requireEnvVar('DEPLOY_ENV').toLowerCase() as DeployEnv

// Auth0
export const AUTH0_DOMAIN = requireEnvVar('AUTH0_DOMAIN')
export const AUTH0_CLIENT_ID = requireEnvVar('AUTH0_CLIENT_ID')
export const AUTH0_RESPONSE_TYPE = requireEnvVar('AUTH0_RESPONSE_TYPE')

// Datadog
export const DD_APPLICATION_ID = requireEnvVar('DD_APPLICATION_ID')
export const DD_CLIENT_TOKEN = requireEnvVar('DD_CLIENT_TOKEN')
export const DD_SAMPLE_RATE = parseInt(requireEnvVar('DD_SAMPLE_RATE'), 10)
export const DD_SERVICE = requireEnvVar('DD_SERVICE')
export const DD_VERSION = requireEnvVar('DD_VERSION')

export const DISABLE_ENV_CHIP = requireEnvVar('DISABLE_ENV_CHIP') === 'true'
