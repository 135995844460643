import { lazy } from 'react'

import AccessTimeIcon from '@mui/icons-material/AccessTime'

import { ConsoleConfig, ConsoleID } from 'lib/consoleConfigs'

import {
  PFTM_CONSOLE_APP_API_ENDPOINT,
  PFTM_CONSOLE_APP_AUTH0_AUDIENCE,
} from '../utils/environment-variables'

export const pftmConfig = {
  id: ConsoleID.PFTM,

  name: 'Plate Flow Time Machine',
  Icon: AccessTimeIcon,
  description: 'View snapshots of previous plates and paths',

  path: '/pftm',
  Console: lazy(() => import('consoles/pftm')),

  apis: {
    pftm: {
      uri: PFTM_CONSOLE_APP_API_ENDPOINT,
      audience: PFTM_CONSOLE_APP_AUTH0_AUDIENCE,
      permissions: {
        namespace: 'person_service',
      },
      resourceAliases: {
        plate: 'getPFTMPlate',
        paths: 'getPFTMPaths',
      },
    },
  },

  permissions: {
    rolePrefix: 'person_service',
    hasResourceCRUDPermissions: false,
  },

  team: {
    name: 'Platform Services',
    href: 'https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/2799108932/Platform+Services+Team',
  },
} satisfies ConsoleConfig
